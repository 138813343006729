import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody, Table, Button, Tooltip} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxOpen, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import ProductPhysicalDetailsModal from "../molecules/modals/ProductPhysicalDetailsModal.js";


const ProductPhysicalDetailsIconButton = ({ quote, lineItemSaturnDataProductVariant }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const toggleModalOpen = () => setIsModalOpen(!isModalOpen);


    if (!lineItemSaturnDataProductVariant) return null;
    const incompleteWeightVolume = lineItemSaturnDataProductVariant.weight <= 0
        || (lineItemSaturnDataProductVariant.width * lineItemSaturnDataProductVariant.height * lineItemSaturnDataProductVariant.depth <= 0);

    const icon = incompleteWeightVolume ? faTriangleExclamation : faBoxOpen;
    const style = incompleteWeightVolume ? { color: 'orange', cursor: 'pointer' } : {color: "#007bff"};

    return (
        <div>
            <ProductPhysicalDetailsModal
                isOpen={isModalOpen}
                toggle={toggleModalOpen}
                lineItemSaturnDataProductVariant={lineItemSaturnDataProductVariant}
                quote={quote}
            />
            <Button
                    color="link"
                    style={style}
                    tabIndex="-1"
                    onClick={toggleModalOpen}
                >
                <FontAwesomeIcon icon={icon} id="WarningTooltip"
                                 className="action-icon"/>
            </Button>
            {incompleteWeightVolume && <Tooltip
                isOpen={tooltipOpen}
                target="WarningTooltip"
                toggle={toggleTooltip}
            >
                Incomplete Weight and Volume Data. Manually verify shipping estimate.
            </Tooltip>}
        </div>
    );
};

ProductPhysicalDetailsIconButton.propTypes = {
    lineItemSaturnDataProductVariant: PropTypes.object.isRequired,
    quote: PropTypes.object.isRequired,
};

export default ProductPhysicalDetailsIconButton;