import React from 'react';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LockableLineItemField from "../atoms/inputs/LockableLineItemField.js";
import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem"
import { Button, FormFeedback, Input, InputGroup, InputGroupText, Badge, Tooltip } from "reactstrap";
import TitleSearchModal from "../molecules/modals/TitleSearchModal";
import { percentFormatter, usdFormatter, cadFormatter, eurFormatter, gbpFormatter } from "../../helper/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faBoxOpen, faInfoCircle, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import ButtonWithToolTip from "../atoms/ButtonWithTooltip";
import ProductPhysicalDetailsModal from "../molecules/modals/ProductPhysicalDetailsModal";
import NumericInput from "../atoms/inputs/NumericInput";
import ProductPhysicalDetailsIconButton from "../atoms/ProductPhysicalDetailsIconButton.js";

class QuoteLineItemRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locked: !!props.lineItem.productId,
            loading: false,
            productSearchResults: null,
            productDetails: null,
            isTitleSearchModalOpen: false,
            filteredResults: [],
            userInput: '',
            skuSearchTimeout: null,
            tooltipOpen: false,
            quantityInput: props.lineItem.quantity.toString(),
            quantityError: false,
            editingDiscount: props.lineItem.discount ? props.lineItem.discount.toString() : '',
            editingPrice: props.lineItem.price ? props.lineItem.price.toString() : '',
            skuLocked: props.lineItem.isFromMultipleSkuModal || !!props.lineItem.productId,
            titleLocked: props.lineItem.isFromMultipleSkuModal || !!props.lineItem.productId,
            backorderTooltipOpen: false,
        };

        this.handleSkuKeyDown = this.handleSkuKeyDown.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
    }

    async handleLineItemChange(index, field, value) {
        this.props.patchLineItem({ lineItemIndex: index, key: field, value });
        if (field === 'sku') {
            clearTimeout(this.state.skuSearchTimeout);
            if (!value.trim()) {
                return;
            }
            this.setState({
                skuSearchTimeout: setTimeout(() =>
                        this._handleProductSkuChanged({
                                index,
                                sku: value
                            },
                            { requireSkuFieldToMatch: true }
                        ).catch(err => {
                            // No further error processing needed
                        }),
                    250)
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { quantity, price, discount } = this.props.lineItem;

        const newQuantityInput = quantity !== null && quantity !== undefined ? quantity.toString() : '';
        const newEditingPrice = price !== null && price !== undefined ? price.toString() : '';
        const newEditingDiscount = discount !== null && discount !== undefined ? discount.toString() : '';

        // Hopefully prevent infinite loop by checking if state actually needs to be updated
        if (
            (prevProps.lineItem.quantity !== quantity && this.state.quantityInput !== newQuantityInput) ||
            (prevProps.lineItem.price !== price && this.state.editingPrice !== newEditingPrice) ||
            (prevProps.lineItem.discount !== discount && this.state.editingDiscount !== newEditingDiscount)
        ) {
            this.setState({
                quantityInput: newQuantityInput,
                editingPrice: newEditingPrice,
                editingDiscount: newEditingDiscount,
            });
        }
    }

    //TODO Move this to domain
    async _buildNewQuoteLineItemFromApiData({ sku, product }) {
        console.log('Product data:', product);
        if (!product && this.props.lineItem.productId) {
            return new QuoteLineItem({
                productId: null,
                sku: sku,
                title: "",
                quantity: 1,
            }, this.props.quote);
        } else {
            console.log(product);

            const productId = product.product_variant.product_id;

            const retailPrice = product.price || 0; // Guaranteed from the given data structure
            const salePrice = product.sale_price && product.sale_price > 0 ? product.sale_price : null;

            const unitCostUsd = product.product_warehouse?.cost_price ?? 0;

            const finalPrice = salePrice || retailPrice;

            const weight = product.weight ?? 0;
            const width = product.dimensions?.width ?? 0;
            const height = product.dimensions?.height ?? 0;
            const depth = product.dimensions?.depth ?? 0;

            const productCard = await this.props.quoteToolClient.getSaturnDataBySaturnProductIdForCompanyId({
                saturnProductId: productId,
                companyId: this.props.quote.companyId
            }).catch(err => {
                console.log("Failed to get product card", err);
                return [];
            });

            const lineItem = new QuoteLineItem({
                productId: productId,
                sku: product.sku,
                title: product.name,
                quantity: 1,

                unitCostUsd: unitCostUsd,

                retailPrice: retailPrice,

                price: finalPrice,

                discount: 0,
                saturnData: { _raw: productCard },
                weight: weight,
                dimensions: {
                    width: width,
                    height: height,
                    depth: depth
                },
            }, this.props.quote);
            console.log(lineItem);
            return lineItem;
        }
    }


    /**
     *
     * @param index
     * @param sku
     * @param requireSkuFieldToMatch This additional option was added so that it can be set to true from the
     * handleLineItemChange method triggered when the user physically types into the SKU field. This adds an additional
     * check that prevents the application from accepting a SKU that has since been changed.
     * @returns {Promise<void>}
     * @private
     */
    async _handleProductSkuChanged({ index, sku }, { requireSkuFieldToMatch = false } = {}) {
        try {
            const product = await this.props.quoteToolClient.getSaturnProductBySkuForCompanyId({sku, companyId: this.props.quote.companyId});

            if (requireSkuFieldToMatch && this.props.lineItem.sku !== sku)
                return; // The user is typing into this field and the current result no longer matches what they are typing. Disregard this result
            const newLineItemObject = await this._buildNewQuoteLineItemFromApiData(
                { sku, product });

            this.props.setLineItem({ lineItemIndex: index, newLineItemObject });

            this.props.patchLineItem({ lineItemIndex: index, key: 'weight', value: product.properties.weight });
            this.props.patchLineItem({
                lineItemIndex: index,
                key: 'dimensions',
                value: {
                    width: product.dimensions.width,
                    height: product.dimensions.height,
                    depth: product.dimensions.depth
                }
            });

            this.setState({ productDetails: product, skuLocked: true, titleLocked: true });
        } catch (error) {
            if (error.code === 404) {
                this.props.patchLineItem({ lineItemIndex: index, key: 'title', value: '' });
                this.props.patchLineItem({ lineItemIndex: index, key: 'productId', value: null });
                return;
            }

            if (requireSkuFieldToMatch && this.props.lineItem.sku !== sku) {
                return;
            }

            console.error('Error searching for product:', error.message);
            if (error.structuredErrorData) {
                //TODO move to Atom
                const errorData = <div><b>MISSING DATA</b><br />Failed to Get:
                    <ul>
                        {error.structuredErrorData.productData ? "" : <li>Product Data</li>}
                        {error.structuredErrorData.productWarehouse ? "" : <li>Warehouse Data</li>}
                        {error.structuredErrorData.productStore ? "" : <li>Store Data</li>}
                    </ul>
                </div>
                toast.error(errorData);
            } else {
                toast.error("Unable to populate product details for this product. Check your connection or contact support if error persists");
            }
            throw error;
        }
    }

    handleProductSelected = (sku, title) => {
        // When a product is selected from the search screen mark the page as loading to prevent changes
        //Then lookup the product by SKU and populate the data.
        //Finally mark loading as false. If no errors set locked as true
        this.setState({ loading: true }, async () => {
            let noError = true;
            try {
                await this._handleProductSkuChanged({ index: this.props.index, sku });
            } catch (err) {
                console.log(`Error while populating product from search. Row will not be locked`);
                noError = false;
            } finally {
                this.setState({ loading: false, locked: noError });
            }
        });
    };

    toggleTooltip = () => {
        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen
        }));
    };

    handleTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim();
            this.performTitleSearch(inputValue);
        }
    };

    performTitleSearch = async (inputValue) => {
        if (inputValue) {
            try {
                const searchResults = await this.props.quoteToolClient.searchSaturnProductsByNameForCompanyId({
                    name: inputValue,
                    companyId: this.props.quote.companyId,
                });
                console.log('Search Results', searchResults);
                this.setState({
                    isTitleSearchModalOpen: searchResults.length > 0,
                    productSearchResults: searchResults,
                });
            } catch (error) {
                toast.error(
                    <div>
                        <div>
                            <b>Product Search Error</b>
                        </div>
                        {error.userMessage}
                    </div>
                );
                console.error('Error searching for product:', error);
            }
        }
    };

    // TODO: Currently Broken
    handleSkuKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const inputValue = e.target.value.trim().toLowerCase();
            if (!inputValue) {
                return; // Do nothing if the input is empty
            }
            try {
                const searchResults = await this.props.quoteToolClient.getSaturnProductBySkuForComapnyId({sku: inputValue, companyId: this.props.companyId});
                console.log('Search Results', searchResults);
                this.setState({ isTitleSearchModalOpen: searchResults && searchResults.length > 0, productSearchResults: searchResults });
            } catch (error) {
                if (inputValue) {
                    toast.error(
                        <div>
                            <div>
                                <b>Product Search Error</b>
                            </div>
                            {error.userMessage}
                        </div>
                    );
                    console.error('Error searching for product:', error);
                }
            }
        }
    };

    _searchButtonOnClick = () => {
        const titleValue = this.props.lineItem.title.trim();
        this.performTitleSearch(titleValue);
    };

    handleDiscountButtonClick = (index, value) => {
        this.setState({ editingDiscount: value.toString() }, () => {
            this.handleDiscountBlur(index);
        });
    };


    handleDiscountChange = (value) => {
        this.setState({ editingDiscount: value });
    };


    handleQuantityChange(index, value) {
        if (value === '') {
            this.props.patchLineItem({ lineItemIndex: index, key: 'quantity', value: 0 });
            this.setState({
                quantityError: false,
                quantityInput: ''
            });
            return;
        }

        const quantity = parseInt(value, 10);
        const isOverQuantity = quantity > (this.props.lineItem.saturnData?.productWarehouse ? this.props.lineItem.saturnData?.productWarehouse.available : 0);

        this.props.patchLineItem({ lineItemIndex: index, key: 'quantity', value: quantity });

        this.setState({
            quantityError: isOverQuantity,
            quantityInput: quantity.toString()
        });
    }

    handleQuantityBlur = (index) => {
        const { lineItem } = this.props;
        const quantity = parseFloat(this.state.quantityInput) || 0;

        this.handleQuantityChange(index, quantity);
    };


    handlePriceBlur = (index) => {
        const { editingPrice } = this.state;
        const parsedValue = parseFloat(editingPrice);
        const valueToUpdate = isNaN(parsedValue) ? 0 : parsedValue;
        this.props.updateValue(valueToUpdate, ['lineItems', index, 'price'], 2);
    };

    handlePriceChange = (value) => {
        this.setState({ editingPrice: value });
    };

    handleBackorderChange = () => {
        const { index, lineItem } = this.props;
        this.props.patchLineItem({
            lineItemIndex: index,
            key: 'isBackordered',
            value: !lineItem.isBackordered
        });
    };

    toggleBackorderTooltip = () => {
        this.setState(prevState => ({ backorderTooltipOpen: !prevState.backorderTooltipOpen }));
    };

    handleDiscountBlur = (index) => {
        const { editingDiscount } = this.state;
        const parsedValue = parseInt(editingDiscount, 10);
        const validValue = isNaN(parsedValue) ? 0 : parsedValue;

        this.props.patchLineItem({ lineItemIndex: index, key: 'discount', value: validValue });
    };

    toggleEditSKU = () => {
        this.setState(prevState => ({ skuLocked: !prevState.skuLocked }));
    }

    toggleEditTitle = () => {
        this.setState(prevState => ({ titleLocked: !prevState.titleLocked }));
    }

    _renderAvailable(available){
        if(isNaN(available))
            return <span className="small-italic">Not Avail</span>
        return available !== "" ? `${available} (${available - this.props.lineItem.quantity})` : "";
    }

    render() {
        const { index, lineItem, quote, lineNumber, companyId } = this.props;

        const { productDetails, quantityError, isEditingTitle, isEditingSKU } = this.state;

        // Extract product store and determine prices, took a lot of debugging to figure out
        const productStore = lineItem.saturnData?._raw?.product_variants[0]?.product_warehouses_active.find(warehouse =>
            warehouse.product_stores.find(store => store.store_id === companyId)
        )?.product_stores.find(store => store.store_id === companyId);

        const regularPrice = productStore?.price || lineItem.retailPrice;
        const salePrice = productStore?.sale_price > 0 ? productStore?.sale_price : null;

        const isArchived = productStore && productStore.is_active === 0;

        let available;
        if(this.props.lineItem.productId)
            available = this.props.lineItem.saturnData?.productWarehouse?.available;
        else
            available = "";

        const { editingDiscount } = this.state;

        const currencyFormatter = usdFormatter; //quote.getCurrencyFormatter().formatter;
        const isDuplicate = quote.lineItems.filter(item => item.sku === lineItem.sku).length > 1;
        const profitClass = lineItem.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';
        const inventoryClass = available - lineItem.quantity <= 0 ? 'profit-negative' : '';

        return (
            <tr key={index}>
                <td>{lineNumber}</td>
                <td>
                    <InputGroup>
                        <div className="sku-label">
                            <LockableLineItemField
                                value={lineItem.sku}
                                isLocked={this.state.skuLocked}
                                onBlurHandler={() => this.setState({ skuLocked: true, titleLocked: true })}
                                inputProperties={{
                                    onChange: (e) => this.handleLineItemChange(index, 'sku', e.target.value),
                                    style: { color: isDuplicate ? 'red' : 'black' }
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={this.toggleEditSKU}
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                            />
                            {lineItem.sku && isDuplicate && <Badge color="danger" pill>Duplicate SKU</Badge>}
                            {isArchived && <Badge color="danger" pill>Archived SKU</Badge>}
                        </div>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup>
                        <LockableLineItemField
                            value={lineItem.title}
                            isLocked={this.state.titleLocked}
                            onBlurHandler={() => this.setState({ titleLocked: true })}
                            inputProperties={{
                                onChange: (e) => this.handleLineItemChange(index, 'title', e.target.value),
                                onKeyDown: this.handleTitleKeyDown,
                            }}
                        />
                        {this.state.titleLocked ? (
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={this.toggleEditTitle}
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faSearch}
                                /* Using on mouse down because the onBlur triggers before the onClick, so the
                                first time you would try to click fasearch it would never work, but would the second
                                time since the input box wasn't focused */
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    this._searchButtonOnClick();
                                }}
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                            />
                        )}

                    </InputGroup>
                    {this.state.isTitleSearchModalOpen && (
                        <TitleSearchModal
                            isOpen={this.state.isTitleSearchModalOpen}
                            productsData={this.state.productSearchResults}
                            onClose={() => this.setState({ isTitleSearchModalOpen: false })}
                            onProductSelected={this.handleProductSelected}
                        />
                    )}
                </td>
                <td>
                    <NumericInput
                        value={this.state.quantityInput}
                        onChange={(value) => this.handleQuantityChange(index, value)}
                        onBlur={() => this.handleQuantityBlur(index)}
                        decimalPlaces={0}
                        className="text-center"
                        style={{
                            borderColor: quantityError ? 'red' : 'initial',
                            minWidth: '7ch',
                            maxWidth: '50px'
                        }}
                    />
                </td>
                <td className={`inventory-level ${inventoryClass}`}>
                    {
                        this._renderAvailable(available)
                    }
                    {lineItem.sku && (
                        <>
                            <Input
                                type="checkbox"
                                checked={lineItem.isBackordered}
                                onChange={this.handleBackorderChange}
                                id={`backorder-checkbox-${index}`}
                                style={{outline: '1px solid black', marginLeft: '3px' }} // Bolder outline, can easily be removed
                            />
                            <Tooltip
                                placement="top"
                                isOpen={this.state.backorderTooltipOpen}
                                target={`backorder-checkbox-${index}`}
                                toggle={this.toggleBackorderTooltip}
                            >
                                Backordered Status
                            </Tooltip>
                        </>
                    )}
                </td>
                <td>
                    <LockableLineItemField
                        value={lineItem.unitCost}
                        lockedFormatter={currencyFormatter}
                        isLocked={!!this.props.lineItem.productId}
                        inputProperties={{
                            type: "number",
                            onChange: (e) => this.handleLineItemChange(index, 'unitCostUsd', parseFloat(e.target.value))
                        }}
                    />
                </td>
                <td>
                    {salePrice ? (
                        <>
                            <LockableLineItemField
                                value={salePrice}
                                lockedFormatter={currencyFormatter}
                                isLocked={!!this.props.lineItem.productId}
                                inputProperties={{
                                    type: "number",
                                    onChange: (e) => this.handleLineItemChange(index, 'retailPrice', parseFloat(e.target.value))
                                }}
                                className="sale-price"
                            />
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                id={`tooltip-${index}`}
                                style={{marginLeft: '5px', color: 'blue', cursor: 'pointer'}}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={this.state.tooltipOpen}
                                target={`tooltip-${index}`}
                                toggle={this.toggleTooltip}>
                                Promotional Price! Normally: ${regularPrice}
                            </Tooltip>
                        </>
                    ) : (
                        <LockableLineItemField
                            value={lineItem.retailPrice}
                            lockedFormatter={currencyFormatter}
                            isLocked={!!this.props.lineItem.productId}
                            inputProperties={{
                                type: "number",
                                onChange: (e) => this.handleLineItemChange(index, 'retailPrice', parseFloat(e.target.value))
                            }}
                        />
                    )}
                </td>
                <td>
                    <div className="discount-button-grid">
                        {[0, 5, 10, 20].map((discountValue) => (
                            <Button
                                key={discountValue}
                                color={parseInt(this.state.editingDiscount) === discountValue ? 'primary' : 'secondary'}
                                onClick={() => this.handleDiscountButtonClick(index, discountValue)}
                                style={{width: '100%', margin: '0px', padding: '1px', fontSize: '0.8em'}}
                            >
                                {discountValue}%
                            </Button>
                        ))}
                    </div>
                </td>

                <td>
                    <InputGroup>
                        <InputGroupText className="small-padding-input-group">$</InputGroupText>
                        <NumericInput
                            value={this.state.editingPrice}
                            onChange={this.handlePriceChange}
                            onBlur={() => this.handlePriceBlur(index)}
                            style={{minWidth: '9ch', maxWidth: '9ch'}}
                        />

                    </InputGroup>
                </td>
                <td className={profitClass}>{currencyFormatter(lineItem.profitTotal)} ({percentFormatter(lineItem.profitPercentage)})</td>
                <td>{currencyFormatter(lineItem.extendedLineTotal)}</td>
                <td>
                    <div className="action-icons">
                        <Button
                            color="link"
                            style={{color: "#dc3545"}}
                            tabIndex="-1"
                            onClick={() => this.props.patchQuoteAction({action: "removeItem", data: {index}})}
                        >
                            <FontAwesomeIcon icon={faX} className="action-icon"/>
                        </Button>
                        <ProductPhysicalDetailsIconButton
                            lineItemSaturnDataProductVariant={this.props.lineItem.saturnData.productVariant}
                            quote={quote}
                        />
                    </div>
                </td>
            </tr>
        );
    }
}

QuoteLineItemRow.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    lineItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    lineNumber: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    isUSCompany: PropTypes.bool.isRequired
};

export default QuoteLineItemRow;