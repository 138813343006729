import { useState } from 'react';
import {Card, CardBody, CardTitle, CardText, Button, Badge, Spinner, Tooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faX } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Address from "../atoms/Address.js";
import AddressCardText from "../atoms/AddressCardText.js";
import quote from "@kjdelectronics/ps-quotetool-domain/obj/quote/Quote.js";

const AddressCard = ({ index, account, address, onAccountUpdated, isDefaultBilling, isDefaultShipping, addressEditInProgress,
                         setAddressEditInProgress, isNewAddress, onClearNewAddress, isSelectionMode, onAddressSelected,
                         handleSetDefaultAddress, quoteToolClient }) => {
    const [isEditing, setIsEditing] = useState(isNewAddress);
    const [currentAddress, setCurrentAddress] = useState(address);
    const [isSaving, setIsSaving] = useState(false); // State to track saving/loading
    const [shippingDefaultTooltipOpen, setShippingDefaultTooltipOpen] = useState(false); // Tooltip state
    const [billingDefaultTooltipOpen, setBillingDefaultTooltipOpen] = useState(false); // Tooltip state


    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        setAddressEditInProgress(!isEditing);
        if(isNewAddress)
            onClearNewAddress();
    }

    const handleAddressUpdated = (updatedAddress) => {
        setCurrentAddress(updatedAddress);
    };

    const handleDeleteAddress = async (addressId) => {
        const newAccount = await quoteToolClient.deleteAccountAddress({accountId: account.id, addressId});
        onAccountUpdated(newAccount);
        toast.info("Address removed.");
    }

    const handleSave = async () => {
        setIsSaving(true); // Show loading spinner
        try {
            let newAccount;
            if(isNewAddress){
                newAccount = await quoteToolClient.createAccountAddress({
                    accountId: account.id,
                    address: currentAddress,
                });
                onClearNewAddress();
            }
            else {
                newAccount = await quoteToolClient.patchAccountAddress({
                    accountId: account.id,
                    addressId: currentAddress.id,
                    patch: currentAddress,
                });
            }
            onAccountUpdated(newAccount);
            setIsEditing(false); // Exit edit mode
            setAddressEditInProgress(false);
            toast.success('Address updated successfully');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update the address');
        } finally {
            setIsSaving(false); // Hide loading spinner after saving
        }
    };

    return (
        <Card className="mb-3 position-relative" style={{ height: 'auto' }}>
            {/* Spinner overlay */}
            {isSaving && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent overlay
                    zIndex: 1, // Make sure spinner is above the card content
                }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </div>
            )}
            <CardBody>
                {/* Pencil icon in the top right */}
                {!isEditing && (
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={toggleEditMode}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            cursor: 'pointer',
                            fontSize: '1.2rem'
                        }}
                    />
                )}
                {/* Labels for Default Billing and Shipping */}
                {isDefaultBilling && <Badge color="info" className="me-1">Default Billing</Badge>}
                {isDefaultShipping && <Badge color="success" className="me-1">Default Shipping</Badge>}

                {!isEditing ? (
                    <>
                        {/* Address Information */}
                        <CardText>
                           <AddressCardText address={address}/>
                        </CardText>

                        {/* Button container with flexbox for horizontal alignment */}
                        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                            {!isDefaultShipping && (
                               <div id={`shippingWrapper-${index}`} style={{ position: 'relative' }}>
                                   <Button
                                    bsSize="sm"
                                    outline
                                    color="success"
                                    disabled={addressEditInProgress}
                                    onClick={() => handleSetDefaultAddress("shipping", address.id)}
                                >
                                    Default Shipping
                               </Button>
                                   {addressEditInProgress && <Tooltip placement="top" isOpen={shippingDefaultTooltipOpen} target={`shippingWrapper-${index}`}
                                                                      toggle={() => setShippingDefaultTooltipOpen(!shippingDefaultTooltipOpen)}>
                                       Save/Cancel all address edits before changing default addresses
                                   </Tooltip>}
                               </div>
                            )}
                            {!isDefaultBilling && (
                                <div id={`billingWrapper-${index}`} style={{ position: 'relative' }}>
                                    <Button
                                    bsSize="sm"
                                    outline
                                    color="primary"
                                    disabled={addressEditInProgress}
                                    onClick={() => handleSetDefaultAddress("billing", address.id)}
                                >
                                    Default Billing
                                </Button>
                                    {addressEditInProgress && <Tooltip placement="top" isOpen={billingDefaultTooltipOpen} target={`billingWrapper-${index}`}
                                                                       toggle={() => setBillingDefaultTooltipOpen(!billingDefaultTooltipOpen)}>
                                        Save/Cancel all address edits before changing default addresses
                                    </Tooltip>}
                                </div>
                            )}
                            <Button
                                bsSize="sm"
                                outline
                                color="danger"
                                disabled={addressEditInProgress}
                                onClick={() => handleDeleteAddress(address.id)}
                            >
                                <FontAwesomeIcon
                                    icon={faX}
                                />
                            </Button>
                        </div>
                        {isSelectionMode && (
                            <Button
                                color="primary"
                                style={{ width: '100%', marginTop: '10px' }}
                                onClick={() => onAddressSelected(address)}
                            >
                                Select
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Address
                            address={currentAddress}
                            addressType="edit"
                            header=" "
                            onAddressUpdated={handleAddressUpdated}
                            companyId={account.defaults.companyId}
                            isNotesEditable={true}
                        />
                        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                            {/* Save and Cancel buttons */}
                            <Button bsSize="sm" color="primary" className="me-2"
                                    onClick={handleSave}>
                                Save
                            </Button>
                            <Button bsSize="sm" color="secondary" onClick={toggleEditMode}>Cancel</Button>
                        </div>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default AddressCard;
