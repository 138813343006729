import React from 'react';
import PropTypes from 'prop-types';
import {getWeightDimensionUnitsFromCompanyId} from "../../../helper/weightDimensionUnits.helper.js";

const ShippingOptions = ({ value, onChange, quote, isUSCompany }) => {
    // If US: line items weight in ounces (oz), convert total to lbs (1 lb = 16 oz)
    // If CA: line items weight in grams (g), convert total to kg (1 kg = 1000 g)

    const {weightUnit, dimensionUnit } = getWeightDimensionUnitsFromCompanyId(quote.companyId);
    console.log({weightUnit, dimensionUnit });
    if(weightUnit !== "oz" && weightUnit !== "g")
        return `Invalid Weight Units ${weightUnit}`;
    if(dimensionUnit !== "inches" && dimensionUnit !== "cm")
        return `Invalid Dimension Units ${dimensionUnit}`;

    //16 from ounces to lbs
    //1000 from grams to kg
    const weightConversionFactor = weightUnit === "oz" ? 16 : 1000;

    //1728 from cubic inches to cubic feet
    //1e+6 from cubic cm to cubic meter
    const dimensionConversionFactor = dimensionUnit === "inches" ? 1728 : 1e+6;

    const totalWeight = quote.totals.totalWeight / weightConversionFactor;
    const totalVolume = quote.totals.totalVolume / dimensionConversionFactor;

    return (
        <div className="form-column">
            <h4>Shipping Service <span style={{"font-size": "12pt"}}>({totalWeight.toFixed(1)} {weightUnit === "oz" ? 'lbs' : 'kg'} / {totalVolume.toFixed(1)} {dimensionUnit === "inches" ? 'cubic feet' : 'cubic meters'})</span></h4>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    style={{ maxWidth: 350 }}
                    placeholder="Enter shipping option..."
                    value={value}
                    onChange={e => onChange(e.target.value)}
                />
            </div>
        </div>
    );
};

ShippingOptions.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    isUSCompany: PropTypes.bool.isRequired
};

export default ShippingOptions;
