import React from 'react';
import PropTypes from 'prop-types';
import Navbar from "../molecules/NavBar.js";
import { QuoteOrganism } from "../organism/QuoteOrganism";
import withRouter from "../../helper/withRouter";
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import Loading from "../atoms/loading/Loading.js";
import AppContext from "../../context/AppContext.js";
import { getCompanyIdFromUrlSegment } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import { SATURN_COMPANIES } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/saturn.company.js";
import {useSearchParams} from "react-router-dom";
import {Alert, Button} from "reactstrap";


const cookies = new Cookies();

class QuotePage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            customerEmail: '',
            customerId: '',
            helpScoutTicket: '',
            companyId: null,
            companyName: null,
            company: null,
            existingQuoteRef: null,
            errorMessage: null,
        };
    }

    componentDidMount() {
        this.loadQuoteData(this.props);
    }

    componentDidUpdate(prevProps) {
        const prevQuoteRef = prevProps.router.params.quoteRef;
        const currentQuoteRef = this.props.router.params.quoteRef;

        if (prevQuoteRef !== currentQuoteRef) {
            if (currentQuoteRef && !isNaN(currentQuoteRef)) {
                this.loadQuoteData(this.props);
            } else {
                this.setState({ errorMessage: "Invalid Quote Id", existingQuoteRef: null });
            }
        }
    }

    loadQuoteData(props) {
        const companyUrlSegment = props.router.params.companyUrlSegment;
        let companyId = getCompanyIdFromUrlSegment(companyUrlSegment);
        const existingQuoteRef = props.router.params.quoteRef;

        if (!companyId) {
            console.error("Invalid or missing companyId for URL segment:", companyUrlSegment);
            this.setState({ errorMessage: "Invalid company." });
            return;
        }

        let companyName = "";
        let company = null;

        console.log("AppContext in componentDidMount:", this.context);

        if (this.context) {
            const { companies } = this.context;

            const companiesArray = Array.isArray(companies)
                ? companies
                : Array.isArray(companies?.data)
                    ? companies.data
                    : null;

            if (Array.isArray(companiesArray)) {
                company = companiesArray.find(c => c.id === companyId);
                if (!company) {
                    console.error("Company not found in context:", { companyId, companies: companiesArray });
                } else {
                    companyName = company.name || "";
                }
            } else {
                console.error("Invalid companies structure in context:", companies);
                const fallbackCompany = SATURN_COMPANIES[companyId];
                if (fallbackCompany) {
                    companyName = fallbackCompany.name;
                } else {
                    console.warn("No company found in SATURN_COMPANIES fallback for companyId:", companyId);
                }
            }
        } else {
            console.error("Context is null or undefined");
        }

        this.setState({
            companyId,
            companyName,
            company,
            existingQuoteRef: existingQuoteRef || null,
            errorMessage: null,
        });
    }

    onQuoteCreated(newQuoteRef) {
        let path = this.props.router.location.pathname;
        if (path.slice(-1) === "/")
            path = path.substring(0, path.length - 1);
        this.props.router.navigate(`${path}/${newQuoteRef}`);
        setTimeout(() => window.location.reload(), 50); //TODO Remove: Have to temp fix QUOTE-156
    }

    render() {
        const { companyId, companyName, company, existingQuoteRef, errorMessage } = this.state;
        const { quoteToolClient } = this.props;
        const companyUrlSegment = this.props.router.params.companyUrlSegment;

        if (errorMessage) {
            return (
                <div>
                    <Navbar
                        quoteId={existingQuoteRef}
                        subHeading={existingQuoteRef ? `Quote ${existingQuoteRef}` : "New Quote"}
                        companies={this.context?.companies || []}
                        companyId={companyId}
                    />
                    <div className="mx-4 mt-3 text-center">
                        <Alert color="danger">{errorMessage}</Alert>
                    </div>
                </div>
            );
        }

        if (!companyId) {
            return <Loading />;
        }

        const { currencyRates } = this.context;

        const queryParams = this.props.router ? new URLSearchParams(this.props.router.location.search) : null;
        const customerEmail = queryParams?.get('customerEmail') || null;
        const helpScoutId = queryParams?.get('helpScoutId') || null;

        const searchParams = new URLSearchParams(window.location.search);

        return (
            <div>
                <Navbar
                    quoteId={existingQuoteRef}
                    subHeading={existingQuoteRef ? `Quote ${existingQuoteRef}` : "New Quote"}
                    companies={this.context?.companies || []}
                    companyId={companyId}
                />
                <QuoteOrganism
                    quoteToolClient={quoteToolClient}
                    accountId={queryParams?.get("accountId")}
                    heading={companyName || "Quote Tool"}
                    existingQuoteRef={existingQuoteRef}
                    key={existingQuoteRef}
                    onQuoteCreated={newQuoteId => this.onQuoteCreated(newQuoteId)}
                    companyId={companyId}
                    customerEmail={customerEmail}
                    helpScoutId={helpScoutId}
                    company={company}
                    isUSCompany={companyId === 1000104959}
                    companyUrlSegment={companyUrlSegment}
                    currencyRates={currencyRates}
                />
            </div>
        );
    }
}

QuotePage.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
};

export default withRouter(QuotePage);