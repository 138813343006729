/**
 * Determines the company segment ('us' or 'ca') based on companyId or storeId.
 *
 * @param {Object} account - The account object.
 * @returns {string} - The company segment ('us', 'ca', or '').
 */
const getCompanySegment = (account) => {
    let companyId = account.defaults?.companyId;

    if (!companyId && account.defaults?.storeId) {
        companyId = account.defaults.storeId;
    }

    if (companyId === 1000104959) return 'us';
    else if (companyId === 1000099708) return 'ca';
    else return '';
};

// Company ID to URL segment mapping
const COMPANY_ID_SEGMENT_MAP = {
    1000104959: 'US',
    1000099708: 'CA',
};

const STORE_NAMES_MAP = {
    1000104959: 'US Company',
    1000099708: 'Canada Company',
};

export {getCompanySegment, COMPANY_ID_SEGMENT_MAP, STORE_NAMES_MAP}