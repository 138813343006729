import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import {toast} from "react-toastify";

const DocumentUploader = ({ documents, parentType, parentId, client, enableTaxExempt = false, onDocumentsUpdated }) => {
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [taxExemptModalOpen, setTaxExemptModalOpen] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState(new Date());
    const [expirationDate, setExpirationDate] = useState('');
    const [isPerpetual, setIsPerpetual] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setEffectiveDate(new Date().toISOString().split('T')[0]);
    }, []);


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error('Please select a file before uploading.');
            return;
        }

        try{
            await client.uploadDocument({
                parentType,
                parentId,
                file: selectedFile,
                filename: selectedFile.name
            });
            setSelectedFile(null);
            setUploadModalOpen(false);
            if(onDocumentsUpdated)
                onDocumentsUpdated();
        }catch(e){
            _handleError(e);
        }
    };

    const handleTaxExemptUpload = async () => {
        if (!selectedFile || !effectiveDate || (!expirationDate && !isPerpetual)) {
            toast.error('All required fields must be filled before saving.');
            return;
        }

        const expiration = isPerpetual ? null : expirationDate;
        await client.uploadDocument({
            parentType: 'accounts',
            parentId,
            file: selectedFile,
            filename: selectedFile.name,
            metadata: {
                effectiveDate: new Date(effectiveDate),
                expirationDate: expiration ? new Date(expiration) : null
            }
        });
        setSelectedFile(null);
        setEffectiveDate(new Date().toISOString().split('T')[0]);
        setExpirationDate('');
        setIsPerpetual(false);
        setTaxExemptModalOpen(false);
        if(onDocumentsUpdated)
            onDocumentsUpdated();
    };

    const _handleError = function(e){
        console.log(e);
        console.log(JSON.stringify(e));
        toast.error(`Error while uploading file: ${e.message}`);
    }

    const handleDelete = async (key) => {
        await client.deleteDocumentByKey(key);
        if(onDocumentsUpdated)
            onDocumentsUpdated();
    };

    const handleDownload = async (key) => {
        const blob = await client.downloadDocumentByKey({ key });
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', key.split('#|#')[1]); // Extract filename
        link.click();
        window.URL.revokeObjectURL(url); // Revoke the object URL to prevent memory leaks
    };

    const renderTaxExemptBadge = (metadata) => {
        const now = new Date();
        const effective = new Date(metadata.effectiveDate);
        const expiration = metadata.expirationDate ? new Date(metadata.expirationDate) : null;

        if (now < effective) return <span className="warning-badge badge me-3 ">Not Yet Valid</span>;
        if (!expiration) return <span className="badge bg-success me-3">Valid - Perpetual</span>;
        if (now > expiration) return <span className="badge bg-danger me-3">Expired</span>;

        const daysUntilExpiration = Math.ceil((expiration - now) / (1000 * 60 * 60 * 24));
        return (
            <span>
                <span className="badge bg-success me-3">Valid - Exp {expiration.toLocaleDateString()}</span>
                {daysUntilExpiration <= 120 && (
                    <span className=" warning-badge badge">Expires Soon ({daysUntilExpiration} Days)</span>
                )}
            </span>
        );
    };

    return (
        <div>
            <ul>
                {documents.map((doc, index) => (
                    <li key={index}>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleDownload(doc.key);
                            }}
                        >
                            {doc.filename}
                        </a>
                        {doc.metadata?.effectiveDate && (
                            <span className="ms-3">{renderTaxExemptBadge(doc.metadata)}</span>
                        )}
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="ms-2 text-danger cursor-pointer"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDelete(doc.key)}
                        />
                    </li>
                ))}
            </ul>
            <div className="d-flex gap-2 mt-3">
                <Button color="primary" onClick={() => setUploadModalOpen(true)}>
                    Upload
                </Button>
                {enableTaxExempt && (
                    <Button color="secondary" onClick={() => setTaxExemptModalOpen(true)}>
                        Upload Tax Exempt Certificate
                    </Button>
                )}
            </div>

            <div
                style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
                onClick={() => setUploadModalOpen(true)}
            >
                <FontAwesomeIcon icon={faFileUpload} /> Drag & drop files or click to upload
            </div>

            {/* Generic Upload Modal */}
            <Modal isOpen={uploadModalOpen} toggle={() => setUploadModalOpen(!uploadModalOpen)}>
                <ModalHeader toggle={() => setUploadModalOpen(!uploadModalOpen)}>Upload Document</ModalHeader>
                <ModalBody>
                    <Input type="file" onChange={handleFileChange} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleUpload}>Save</Button>
                    <Button color="secondary" onClick={() => setUploadModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* Tax Exempt Modal */}
            <Modal isOpen={taxExemptModalOpen} toggle={() => setTaxExemptModalOpen(!taxExemptModalOpen)}>
                <ModalHeader toggle={() => setTaxExemptModalOpen(!taxExemptModalOpen)}>
                    Upload Tax Exempt Certificate
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="effectiveDate">
                                Effective Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="date"
                                id="effectiveDate"
                                value={effectiveDate}
                                onChange={(e) => setEffectiveDate(e.target.value)}
                                required
                            />
                        </FormGroup>
                        {!isPerpetual && (
                            <FormGroup>
                                <Label for="expirationDate">
                                    Expiration Date <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="date"
                                    id="expirationDate"
                                    value={expirationDate}
                                    onChange={(e) => setExpirationDate(e.target.value)}
                                    required
                                />
                            </FormGroup>
                        )}
                        <FormGroup>
                            <Input
                                type="checkbox"
                                id="isPerpetual"
                                checked={isPerpetual}
                                onChange={() => setIsPerpetual(!isPerpetual)}
                            />
                            <Label for="isPerpetual" className="ms-2">Perpetual (No expiration)</Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="fileUpload">
                                File <span className="text-danger">*</span>
                            </Label>
                            <Input type="file" id="fileUpload" onChange={handleFileChange} required />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleTaxExemptUpload}>Save</Button>
                    <Button color="secondary" onClick={() => setTaxExemptModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};


DocumentUploader.propTypes = {
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            filename: PropTypes.string.isRequired,
            metadata: PropTypes.shape({
                effectiveDate: PropTypes.string,
                expirationDate: PropTypes.string
            })
        })
    ).isRequired,
    parentId: PropTypes.string.isRequired,
    parentType: PropTypes.string.isRequired,
    client: PropTypes.shape({
        uploadDocument: PropTypes.func.isRequired,
        deleteDocumentByKey: PropTypes.func.isRequired,
        downloadDocumentByKey: PropTypes.func.isRequired
    }).isRequired,
    enableTaxExempt: PropTypes.bool,
    onDocumentsUpdated: PropTypes.func
};

export default DocumentUploader;
