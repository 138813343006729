import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardText, Badge, Button, Input, FormGroup, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt, faSave, faTimes, faX} from '@fortawesome/free-solid-svg-icons';
import {toast} from "react-toastify";

const AccountContactCard = ({ accountId, contact, isPrimary, isEditing, onEdit, onSave, onCancelEdit, onSetPrimary, quoteToolClient, onAccountUpdated }) => {
    const [editedContact, setEditedContact] = useState(contact);
    const [emailValid, setEmailValid] = useState(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedContact({ ...editedContact, [name]: value });
    };

    const validateEmail = () => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedContact.email);
        setEmailValid(isValid);
        return isValid;
    };

    async function handleDeleteContact(contactId) {
        const newAccount = await quoteToolClient.deleteAccountContact({accountId, contactId});
        onAccountUpdated(newAccount);
        toast.info("Contact removed.");
    }

    return (
        <Card className="mb-3 position-relative" style={{ height: 'auto' }}>
            {!isEditing && (
                <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="position-absolute"
                    style={{ top: '10px', right: '10px', cursor: 'pointer' }}
                    onClick={onEdit}
                />
            )}
            <CardBody>
                {isEditing ? (
                    <>
                        <FormGroup>
                            <Input
                                type="text"
                                name="name"
                                value={editedContact.name}
                                onChange={handleInputChange}
                                placeholder="Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="email"
                                name="email"
                                className="qt-input-style"
                                value={editedContact.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                invalid={!emailValid}
                                onBlur={validateEmail}
                            />
                            <FormFeedback>Invalid email address</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="text"
                                name="phoneNumber"
                                value={editedContact.phoneNumber}
                                onChange={handleInputChange}
                                placeholder="Phone Number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="textarea"
                                name="notes"
                                className="qt-input-style"
                                value={editedContact.notes}
                                onChange={handleInputChange}
                                placeholder="Notes"
                            />
                        </FormGroup>
                        <Button color="primary" bsSize="sm" onClick={() => validateEmail() && onSave(editedContact)}>
                            <FontAwesomeIcon icon={faSave} /> Save
                        </Button>{' '}
                        <Button color="secondary" bsSize="sm" onClick={onCancelEdit}>
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                        </Button>
                    </>
                ) : (
                    <>
                        <CardTitle>
                            {contact.name} {isPrimary && <Badge color="primary">Primary Contact</Badge>}
                        </CardTitle>
                        <CardText>Email: {contact.email}</CardText>
                        <CardText>Phone: {contact.phoneNumber}</CardText>
                        <CardText>Notes: {contact.notes}</CardText>
                        <Button color="primary" bsSize="sm" outline onClick={onSetPrimary} className="me-2">
                            Primary Contact
                        </Button>
                        <Button
                            bsSize="sm"
                            outline
                            color="danger"
                            disabled={isEditing}
                            onClick={() => handleDeleteContact(contact.id)}
                        >
                            <FontAwesomeIcon
                                icon={faX}
                            />
                        </Button>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default AccountContactCard;