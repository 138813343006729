import React from 'react';
import {
    Navbar,
    Row,
    Col,
    NavbarText, Nav, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Button
} from 'reactstrap';
import AppContext from "../../context/AppContext";
import PropTypes from "prop-types";
import { faFileInvoice, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withRouter from "../../helper/withRouter";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { getAuthCookieName } from "../../helper/auth.helper.js";
import { getCompanyIdFromUrlSegment, getUrlSegmentFromCompanyId } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import { SATURN_COMPANIES } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/saturn.company.js";

const AUTH_COOKIE_NAME = getAuthCookieName();

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            companyDropdownOpen: false,
        };
        this.cookies = new Cookies();

        // Binding methods
        this.handleLogoClick = this.handleLogoClick.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleCompanyDropdown = this.toggleCompanyDropdown.bind(this);
        //this.handleCompanySwitch = this.handleCompanySwitch.bind(this);
    }

    handleLogoClick() {
        const { router } = this.props;
        const companyUrlSegment = router.params.companyUrlSegment;

        if (companyUrlSegment) {
            router.navigate(`/${companyUrlSegment}`);
        } else {
            router.navigate('/');
        }
    }

    _renderLogo(company) {
        const logoUrl = company && company.id && SATURN_COMPANIES[company.id]?.logoUrl;

        if (logoUrl) {
            return (
                <img
                    style={{ backgroundColor: "white", cursor: "pointer" }}
                    src={logoUrl}
                    width="125"
                    alt={company?.name || "Company Logo"}
                    onClick={this.handleLogoClick}
                />
            );
        }

        return (
            <FontAwesomeIcon
                icon={faFileInvoice}
                color="white"
                className="fa-4x"
                onClick={this.handleLogoClick}
                style={{ cursor: 'pointer' }}
            />
        );
    }

    _getCompany(companyId, companies) {
        if (!companyId) {
            console.warn('Company ID is undefined');
            return null;
        }
        const company = companies.find(c => c.id === companyId);
        if (!company) {
            console.error('Company not found for ID:', companyId);
            return null;
        }
        return company;
    }

    static _getDefaultHeading(company) {
        if (!company) return "Quote Tool";

        const shortName = company.short_name || "Company";

        return `${shortName} - Quote Tool`;
    }


    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    toggleCompanyDropdown() {
        this.setState(prevState => ({
            companyDropdownOpen: !prevState.companyDropdownOpen,
        }));
    }

    handleLogout = () => {
        this.cookies.remove(AUTH_COOKIE_NAME);
        window.location.replace("/auth/login");
    };

    handleCompanySwitch(selectedCompany) {
        const { router } = this.props;
        const currentPath = router.location.pathname;
        const currentCompanyUrlSegment = router.params.companyUrlSegment;
        const newCompanyUrlSegment = getUrlSegmentFromCompanyId(selectedCompany.id);

        if (!newCompanyUrlSegment) {
            console.error("Invalid company selected");
            return;
        }

        let newPath;
        if (currentCompanyUrlSegment) {
            // Replace the current company segment in the URL with the new one
            const regex = new RegExp(`^/${currentCompanyUrlSegment}`, 'i');
            newPath = currentPath.replace(regex, `/${newCompanyUrlSegment}`);
        } else {
            // Prepend the new company segment to the path
            newPath = `/${newCompanyUrlSegment}${currentPath}`;
        }

        router.navigate(newPath);
    }

    render() {
        return (
            <AppContext.Consumer>
                {({ user, companies }) => {
                    if (!companies || companies.length === 0) {
                        console.warn("Companies data is not available yet.");
                        return null;  // Optionally show a loader here
                    }

                    const companyUrlSegment = this.props?.router?.params?.companyUrlSegment;
                    const companyId = getCompanyIdFromUrlSegment(companyUrlSegment);
                    const company = this._getCompany(companyId, companies);

                    // Now unnecessary but leaving in for any later tweaking
                    const quotationsLink = `/${companyUrlSegment}/quotations`;

                    return (
                        <Navbar color="dark" dark expand="lg" container="fluid">
                            <Row className="align-items-center">
                                <Col xl={5}>
                                    {this._renderLogo(company)}
                                </Col>
                                <Col xl={7}>
                                    <div className="quote-tool-title">
                                        <Row>
                                            <Col>
                                                <strong className="store-name">
                                                    {this.props.heading
                                                        ? this.props.heading
                                                        : NavBar._getDefaultHeading(company)}
                                                </strong>
                                            </Col>
                                        </Row>
                                        <span className="font-italic">
                                        {this.props.subHeading ? this.props.subHeading : ""}
                                    </span>
                                    </div>
                                </Col>
                            </Row>
                            <Nav className="me-auto" navbar>
                                <div className="navbar-container">
                                    <ul className="navbar-links">
                                        <li>
                                            <Link className="navbar-link" to={quotationsLink}>
                                                Quotations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="navbar-link" to={`/${companyUrlSegment}/accounts`}>
                                                Accounts
                                            </Link>
                                        </li>
                                        {user.roles.includes("admin") && (
                                            <li>
                                                <Link className="navbar-link" to="/admin/users">Users</Link>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="navbar-search">
                                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="form-control search-input"
                                            style={{ width: "200px", display: "inline-block" }}
                                        />
                                    </div>
                                </div>
                            </Nav>

                            <Button
                                color="primary"
                                className="me-3"
                                onClick={() => {
                                    this.props.router.navigate(`/${companyUrlSegment}/quotes`);
                                    setTimeout(() => window.location.reload(), 50);
                                }}
                            >
                                New Quote
                            </Button>

                            <NavbarText className="me-5">
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                    <DropdownToggle caret>
                                        {user.firstName ? `Hello, ${user.firstName}` : "Welcome"}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {(user.roles.includes("admin") ||
                                            (user.roles.includes("sales-us") && user.roles.includes("sales-ca"))) && (
                                            <>
                                                <DropdownItem header>Switch Company</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        this.props.router.navigate('/');
                                                    }}
                                                >
                                                    Go to Company Selection
                                                </DropdownItem>
                                                <DropdownItem divider />
                                            </>
                                        )}
                                        <DropdownItem tag={Link} to="/users/change-password">
                                            Change Password
                                        </DropdownItem>
                                        <DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavbarText>
                        </Navbar>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

NavBar.propTypes = {
    logoUrl: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
};

export default withRouter(NavBar);