import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Alert, Badge, Col} from 'reactstrap';
import TaxableSwitch from "./inputs/TaxableSwitch.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus} from "@fortawesome/free-solid-svg-icons";

const TaxableConflictWarning = ({ account, isTaxable, type="account" }) => {
    const [conflictMessage, setConflictMessage] = useState(null);
    const [validTaxExempt, setValidTaxExempt] = useState(false);

    type = type.toLowerCase();

    useEffect(() => {
        const now = new Date();

        //TODO Move this logic to domain
        // Determine if any document is a valid tax-exempt certificate
        const hasValidTaxExemptCertificate = account.documents.some(doc => {
            if(doc.metadata.hasOwnProperty("expirationDate") && doc.metadata.expirationDate === null)
                doc.metadata.expirationDate = "2100-12-31";
            if (doc.metadata?.effectiveDate && doc.metadata?.expirationDate) {
                const effective = new Date(doc.metadata.effectiveDate);
                const expiration = new Date(doc.metadata.expirationDate);
                return effective <= now && now <= expiration;
            }
            return false;
        });

        // Determine if there are expired certificates
        const hasExpiredTaxExemptCertificate = account.documents.some(doc => {
            if (doc.metadata?.effectiveDate && doc.metadata?.expirationDate) {
                const expiration = new Date(doc.metadata.expirationDate);
                return expiration < now;
            }
            return false;
        });

        // Set conflict messages based on conditions
        if (type === "quote" && account.id == null) { //No account so nothing to validate
            setConflictMessage(null);
            setValidTaxExempt(false);
        } else if (hasExpiredTaxExemptCertificate && !hasValidTaxExemptCertificate) {
            setConflictMessage(
                'No valid tax exempt certificate on file for this account. There are expired tax-exempt ' +
                'certificates on file for this account. Please update or remove them by reviewing the account documents.'
            );
            setValidTaxExempt(false);
        }
        else if (isTaxable && hasValidTaxExemptCertificate) {
            setConflictMessage(
                `The ${type} is marked as taxable, but there is a valid tax-exempt certificate on file for this account. Please review.`
            );
            setValidTaxExempt(false);
        } else if (!isTaxable && !hasValidTaxExemptCertificate) {
            setConflictMessage(
                <>
                    <strong>NON-TAXABLE {type.toUpperCase()}</strong> - Missing tax-exempt certificate, to review!
                </>
            );
            setValidTaxExempt(false);
        } else if(!isTaxable){ //Valid tax exempt
            setValidTaxExempt(true);
            setConflictMessage(null);
        }else if(isTaxable && type === "quote" && !account.isTaxable){ //This is a quote that is set to taxable while the account is set to not taxable
            setValidTaxExempt(false);
            setConflictMessage(
                <>
                    <strong>TAXABLE {type.toUpperCase()}</strong> - Conflict detected with account tax status, please review!
                </>
            );
        }
        else {
            setConflictMessage(null);
            setValidTaxExempt(false);
        }


    }, [isTaxable, account.documents]);

    return (
            <div className="ms-5">
                {conflictMessage && (
                    <Alert color="warning" className="taxable-conflict" >
                        {conflictMessage}
                    </Alert>
                )}
                {validTaxExempt && (
                    <Badge color="success" className="ms-4">
                        Valid Tax Exempt Certificate on File
                        <FontAwesomeIcon icon={faCheck} style={{ color: 'white', marginLeft: '5px' }} />
                    </Badge>
                )}
            </div>
    );
};

TaxableConflictWarning.propTypes = {
    account: PropTypes.shape({
        isTaxable: PropTypes.bool.isRequired
    }).isRequired,
    isTaxable: PropTypes.bool,
    type: PropTypes.string,
};

export default TaxableConflictWarning;
