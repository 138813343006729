import React from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import {getWeightDimensionUnitsFromCompanyId} from "../../../helper/weightDimensionUnits.helper.js";

const ProductPhysicalDetailsModal = ({ isOpen, toggle, lineItemSaturnDataProductVariant, quote }) => {
    if (!lineItemSaturnDataProductVariant){
        return null;
        console.error("ProductPhysicalDetailsModal called without lineItemSaturnDataProductVariant. Cannot render");
    }

    const {weightUnit, dimensionUnit } = getWeightDimensionUnitsFromCompanyId(quote.companyId);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Product Physical Details</ModalHeader>
            <ModalBody>
                <Table>
                    <tbody>
                    <tr>
                        <th>Weight</th>
                        <td>{lineItemSaturnDataProductVariant.weight ?? 'N/A'} {weightUnit}</td>
                    </tr>
                    <tr>
                        <th>Width</th>
                        <td>{lineItemSaturnDataProductVariant.width ?? 'N/A'} {dimensionUnit}</td>
                    </tr>
                    <tr>
                        <th>Height</th>
                        <td>{lineItemSaturnDataProductVariant.height ?? 'N/A'} {dimensionUnit}</td>
                    </tr>
                    <tr>
                        <th>Depth</th>
                        <td>{lineItemSaturnDataProductVariant.depth ?? 'N/A'} {dimensionUnit}</td>
                    </tr>
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    );
};

ProductPhysicalDetailsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    product: PropTypes.object,
    quote: PropTypes.object.isRequired
};

export default ProductPhysicalDetailsModal;