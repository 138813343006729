function getWeightDimensionUnitsFromCompanyId(companyId) {
    const isUSCompany = companyId === 1000104959;

    // If US: Weight in oz, Dimensions in inches
    // If CA: Weight in g, Dimensions in cm
    // Any Future Companies can be added/follow this scheme

    const weightUnit = isUSCompany ? 'oz' : 'g';
    const dimensionUnit = isUSCompany ? 'inches' : 'cm';
    return {weightUnit, dimensionUnit};
}

export { getWeightDimensionUnitsFromCompanyId };