import React from 'react';
import AppContext from './AppContext';
import PropTypes from 'prop-types';

/**
 * The AppProvider and AppContext together allow us to configure variables that will be avaliable
 * in a specific "context". By default, React Components only have access to their own state and
 * variables passed in (props AKA Properties or Params). Using Context is a method that allows
 * certain variables to be available to many child components without having to nest params many
 * levels deep (Think of nested function calls passing the same variable as a param over and over again).
 *
 * In this case we are defining the user in our context so that information about the user can be accessed.
 * We will likely  add more "Global" type variables here later.
 */
class AppProvider extends React.Component {
    render() {
        const { user, countryData, companies, currencyRates, children } = this.props;

        return (
            <AppContext.Provider
                value={{
                    user,
                    companies,
                    countryData,
                    currencyRates
                }}
            >
                {children}
            </AppContext.Provider>
        );
    }
}

AppProvider.propTypes = {
    user: PropTypes.object.isRequired,
    companies: PropTypes.array,
    countryData: PropTypes.object,
    currencyRates: PropTypes.object.isRequired,
};

AppProvider.defaultProps = {
    companies: [],
};

export default AppProvider;