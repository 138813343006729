import React from "react";
import QuoteToolNavBar from "../molecules/NavBar.js";
import withRouter from "../../helper/withRouter.js";
import QuoteTable from "../molecules/tables/QuoteTable.js";

class QuotesPage extends React.Component {
    render() {
        const { companyUrlSegment } = this.props.router.params;

        let heading = "All Quotations";
        if (companyUrlSegment === 'US') {
            heading = "Quotations for US Store";
        } else if (companyUrlSegment === 'CA') {
            heading = "Quotations for CA Store";
        }

        return (
            <>
                <QuoteToolNavBar subHeading="Quotations" />
                <h1>{heading}</h1>
                <QuoteTable
                    quoteToolClient={this.props.quoteToolClient}
                    companyUrlSegment={companyUrlSegment}
                />
            </>
        );
    }
}

export default withRouter(QuotesPage);
