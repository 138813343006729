import React from 'react';
import { useError } from '../../../context/ErrorContext.js';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        const { handleError } = this.props;
        this.setState({ errorInfo });
        handleError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <h1>Oops! Something went wrong.</h1>
                    <p>
                        An unexpected error has occurred. Please try one of the following:
                    </p>
                    <ul style={{ textAlign: 'left', display: 'inline-block' }}>
                        <li>Refresh the page.</li>
                        <li>Return to the <a href="/">home page</a>.</li>
                    </ul>

                    {process.env.NODE_ENV === 'development' && (
                        <details style={{ whiteSpace: 'pre-wrap', marginTop: '20px' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </details>
                    )}
                </div>
            );
        }
        return this.props.children;
    }
}

const ErrorBoundaryWrapper = (props) => {
    const { handleError } = useError();
    return <ErrorBoundary handleError={handleError} {...props} />;
};

export default ErrorBoundaryWrapper;