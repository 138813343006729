import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    FormText,
    Alert
} from 'reactstrap';
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import TaxableSwitch from "../../atoms/inputs/TaxableSwitch.js";
import Account from "@kjdelectronics/ps-quotetool-domain/obj/account/Account.js";
import {isEmailValid} from "@kjdelectronics/ps-quotetool-domain/domain/helper/email.validator.js";

class NewAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: props.email,
            customerName: '',
            accountName: '',
            emailValid: null,
            emailWarning: false,
            emailFeedback: '',
            phone: '',
            isTaxable: true,
            isLoading: false,
            accountMatches: []
        };
        this.innerRef = React.createRef();

        console.log("email", props.email);
    }

    componentDidMount() {
        this.validateEmail();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    validateEmail = async () => {
        const { email } = this.state;
        if (email) {
            if(!isEmailValid(email)){
                this.setState({accountMatches: null, emailValid: false, emailWarning: false, emailFeedback: 'The provided email is not valid' });
                return;
            }

            try {
                const searchResults = await this.props.quoteToolClient.getAccounts(email).catch(err => {
                    if(err.code === 404)
                        return []
                    throw err;
                });
                if (searchResults.length === 1 ) {
                    // Be aware the logic is basically backwards, as we are looking for an email that exists as a negative condition
                    this.setState({accountMatches: searchResults, emailValid: null, emailWarning: true, emailFeedback: 'There is exactly 1 account that matches this email. ' +
                            'Click "Use Existing Account" to select the existing account or you may proceed to create an new account using this email' });
                } else if(searchResults.length > 1) {
                    this.setState({accountMatches: searchResults, emailValid: null, emailWarning: true, emailFeedback: `There are ${searchResults.length} accounts that match this search. Are you sure you want to create a new account?` });
                } else {
                    this.setState({accountMatches: searchResults, emailValid: true, emailWarning: false, emailFeedback: 'That email is not in the system!' });
                }
            } catch (error) {
                console.error('Error validating email', error);
                this.setState({accountMatches: [], emailValid: false, emailFeedback: 'Error checking email availability' });
            }
        }
    };

    handleSubmit = async (e) => {
        const { email, customerName, accountName, phone, isTaxable } = this.state;
        const account = new Account({
            name: accountName,
            isTaxable,
            contacts: [
                {
                    email,
                    name: customerName,
                    phoneNumber: phone,
                }
            ],
            defaults: {
                companyId: this.props.companyId
            }
        });

        const localValidation = account.getValidationErrors();
        if(localValidation.isInvalid){
            toast.error('Account does not meet minimum required fields. Must provide contact email');
            return;
        }

        try {
            await this.setState({isLoading: true});
            console.log('Submitting new account:', account);
            const newAccount = await this.props.quoteToolClient.createUpdateAccount(account);
            toast.success(`New Account successfully created`);
            console.log('New Account created:', newAccount);
            this.props.onAccountSelected(newAccount);
            this.props.onClose();
        } catch (error) {
            this.setState({isLoading: false});
            console.error('Error creating account', error);
            toast.error('Something went wrong, account creation failed');
        }
    };

    onClose = () => {
        console.log("Closing new customer modal...");
        // Close the modal
        this.props.onClose();
    }

    get isSingleExistingAccountFound(){
        return this.state.accountMatches && this.state.accountMatches.length === 1;
    }

    render() {
        const { isOpen } = this.props;
        const { email, customerName, phone, accountName, emailValid, emailWarning, emailFeedback } = this.state;

        if(!isOpen)
            return "";

        return (
            <Modal isOpen={isOpen} toggle={this.onClose} centered>
                <ModalHeader toggle={this.onClose}>Create Account</ModalHeader>
                <ModalBody>
                    <Form>
                        <h6 className="text-center">Primary Contact</h6>
                        <FormGroup>
                            <Label for="email">Contact Email <span style={{color: 'red'}}>*</span></Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="customer@example.com"
                                value={email}
                                onChange={this.handleChange}
                                onBlur={this.validateEmail}
                                valid={emailValid === true || emailWarning === true}
                                invalid={emailValid === false}
                                required
                                tabIndex={1}
                            />
                            {emailValid === true && <FormFeedback valid>{emailFeedback}</FormFeedback>}
                            {emailValid === false && <FormFeedback>{emailFeedback}</FormFeedback>}
                            {emailWarning && (
                                <FormFeedback valid className="warning-color" >{emailFeedback}</FormFeedback>
                            )}
                            {!this.isSingleExistingAccountFound && <FormText>You can add more contacts to the account later</FormText>}
                        </FormGroup>
                        {this.isSingleExistingAccountFound &&
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {this.state.accountMatches[0].toString()}
                                <Button className="new-customer-button" color="success" onClick={e => this.props.onAccountSelected(this.state.accountMatches[0])}>Select Existing Account</Button>
                            </div>
                        }
                        <FormGroup>
                            <Label for="name">Contact Name</Label>
                            <Input
                                type="text"
                                name="customerName"
                                id="customerName"
                                className="bootstrap-input"
                                placeholder="John Smith"
                                value={customerName}
                                onChange={this.handleChange}
                                tabIndex={2}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="name">Contact Phone</Label>
                            <Input
                                type="phone"
                                name="phone"
                                id="phone"
                                placeholder="+1 555-222-3333"
                                value={phone}
                                onChange={this.handleChange}
                                tabIndex={3}
                            />
                        </FormGroup>
                        <hr style={{borderTop: '2px solid'}}/>
                        <h6 className="text-center">Account Info</h6>
                        <FormGroup>
                            <Label for="name">Account Name</Label>
                            <Input
                                type="text"
                                name="accountName"
                                id="accountName"
                                className="bootstrap-input"
                                placeholder="ACME, INC"
                                value={accountName}
                                onChange={this.handleChange}
                                tabIndex={4}
                            />
                        </FormGroup>
                        <TaxableSwitch tabIndex={5} isTaxable={this.state.isTaxable} onChange={newValue => this.setState({isTaxable: newValue})} />
                        {!this.state.isTaxable && <Alert color="warning" className="mt-2">
                            You are free to create this account as not taxable. However, a warning will be displayed until you visit the account
                            detail page and upload a valid tax exempt certificate in the documents section.
                        </Alert>}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button tabIndex={6} className="new-customer-button" color="primary" onClick={e => this.handleSubmit(e)}>Create Account</Button>
                    <Button className="close-button-styles" onClick={e => this.onClose(e)}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

NewAccountModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccountSelected: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    email: PropTypes.string,
    companyId: PropTypes.number.isRequired
};

export default NewAccountModal;
