import React from "react";
import PropTypes from "prop-types";

const TaxableSwitch = ({ isTaxable, onChange, text }) => {
    return (
        <div className="form-group row">
            <label className="col-12 col-form-label">
                {text || "Taxable?"}
            </label>
            <div className="col-12">
                <div className="btn-group" role="group" aria-label="Taxable">
                    <button
                        type="button"
                        className={`taxable-button ${isTaxable ? "active" : ""}`}
                        onClick={() => onChange(true)}
                        style={{ whiteSpace: "nowrap" }}
                    >
                        Taxable
                    </button>
                    <button
                        type="button"
                        className={`not-taxable-button ${isTaxable === false ? "active" : ""}`}
                        onClick={() => onChange(false)}
                        style={{ whiteSpace: "nowrap" }}
                    >
                        Not Taxable
                    </button>
                </div>
            </div>
        </div>
    );
};

TaxableSwitch.propTypes = {
    isTaxable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
};

export default TaxableSwitch;
