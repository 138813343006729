import React from 'react';
import { useParams } from 'react-router-dom';
import QuoteToolNavBar from "../molecules/NavBar";
import QuoteTable from "../molecules/tables/QuoteTable";
import AccountsTable from "../molecules/tables/AccountsTable";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

function CompanyDashboard({ quoteToolClient }) {
    const { companyUrlSegment } = useParams();

    console.log("quoteToolClient in App.js", quoteToolClient);


    return (
        <>
            {/* NavBar is shown for navigation */}
            <QuoteToolNavBar subHeading={`Dashboard for ${companyUrlSegment}`} />

            <Container className="my-4">
                {/* Top row: placeholder cards for future stats */}
                <Row className="mb-4">
                    <Col md={4} className="mb-3">
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Placeholder Stat #1</CardTitle>
                                <p>Details or numbers for {companyUrlSegment} in the future.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Placeholder Stat #2</CardTitle>
                                <p>Details or numbers for {companyUrlSegment} in the future.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Placeholder Stat #3</CardTitle>
                                <p>Details or numbers for {companyUrlSegment} in the future.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Main row: left col for Quotes, right col for Accounts */}
                <Row>
                    <Col md={6} className="mb-4">
                        <h4 className="mb-3">Quotes</h4>
                        <QuoteTable
                            quoteToolClient={quoteToolClient}
                            companyUrlSegment={companyUrlSegment}
                        />
                    </Col>

                    <Col md={6} className="mb-4">
                        <h4 className="mb-3">Accounts</h4>
                        <AccountsTable
                            quoteToolClient={quoteToolClient}
                            companyUrlSegment={companyUrlSegment}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CompanyDashboard;