import {toast} from "react-toastify";
import {COMPANY_ID_SEGMENT_MAP} from "./companyUtils.js";
import {getUrlSegmentFromCompanyId} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils.js";

const cloneQuoteAndOpen = async ({quote, quoteToolClient, navigate}) => {
    try {
        const segment = getUrlSegmentFromCompanyId(quote.companyId);
        const newQuote = await quoteToolClient.cloneQuoteByRef({ref: quote.ref, companyUrlSegment: segment});
        navigate(`/${segment}/quotes/${newQuote.ref}`);
    } catch (error) {
        console.error(error);
        toast.error(`Failed to clone quote ${quote.ref}`);
    }
};

export {cloneQuoteAndOpen}