import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import TaxableSwitch from './inputs/TaxableSwitch.js';
import TaxableConflictWarning from "./AccountTaxableConflictWarning.js";
import {getCompanySegment} from "../../helper/companyUtils";
import PropTypes from "prop-types";

const AccountPageHeading = ({ name, account, onAccountNameChange, onTaxableChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentName, setCurrentName] = useState(name || '');

    const toggleEditMode = () => setIsEditing(!isEditing);
    const handleNameBlur = () => {
        onAccountNameChange(currentName);
        setIsEditing(false);
    };

    const companySegment = getCompanySegment(account);

    return (
        <Row className="align-items-center mt-3">
            <Col lg={7}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2>Account -</h2>
                    {isEditing ? (
                        <Input
                            type="text"
                            value={currentName}
                            onChange={(e) => setCurrentName(e.target.value)}
                            onBlur={handleNameBlur}
                            autoFocus
                            style={{marginLeft: '10px', fontSize: '1.5rem', width: 'auto'}}
                        />
                    ) : (
                        <div
                            onClick={() => !name && toggleEditMode()}
                            style={{
                                marginLeft: '10px',
                                cursor: name ? 'default' : 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <h2 style={{margin: 0}}>
                                {name || <i style={{color: 'gray'}}>Add an Account Name</i>}
                            </h2>
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={toggleEditMode}
                                style={{marginLeft: '10px', cursor: 'pointer', fontSize: '1.5rem'}}
                            />
                        </div>
                    )}
                    <TaxableConflictWarning account={account} isTaxable={account.isTaxable}
                                            onTaxableChange={onTaxableChange}/>
                </div>
            </Col>
            <Col className="text-end" lg={1}>
                <TaxableSwitch
                    isTaxable={account.isTaxable}
                    onChange={onTaxableChange}
                    className="me-3"
                    text=" "
                />
            </Col>
            <Col className="text-end ms-2" style={{marginTop: "14px"}} lg={3} md={4} sm={5}>
                {companySegment ? (
                    <Link to={`/${companySegment}/quotes?accountId=${account.id}`} style={{ textDecoration: 'none' }}>
                        <Button
                            size="sm"
                            outline
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                                borderColor: 'green',
                                marginLeft: '15px'
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{ color: 'green', marginRight: '5px' }} /> New Quote
                        </Button>
                    </Link>
                ) : (
                    <Button
                        size="sm"
                        outline
                        disabled
                        style={{
                            color: 'grey',
                            textDecoration: 'none',
                            borderColor: 'grey',
                            marginLeft: '15px'
                        }}
                        title="Company or Store ID is required to create a new quote."
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ color: 'grey', marginRight: '5px' }} /> New Quote
                    </Button>
                )}
            </Col>
        </Row>
    );
};

AccountPageHeading.propTypes = {
    name: PropTypes.string,
    account: PropTypes.shape({
        id: PropTypes.number.isRequired,
        defaults: PropTypes.shape({
            companyId: PropTypes.number,
            storeId: PropTypes.number,
        }),
        isTaxable: PropTypes.bool.isRequired,
    }).isRequired,
    onAccountNameChange: PropTypes.func.isRequired,
    onTaxableChange: PropTypes.func.isRequired,
};

export default AccountPageHeading;
