import React, {useState} from "react";
import PropTypes from "prop-types";
import AppRoutes from "./AppRoutes.js";
import AuthRoutes from "./AuthRoutes.js";
import UserPendingPage from "../pages/UserPendingPage.js";
import LoadingSpinner from "../atoms/loading/LoadingSpinner.js";
import {Progress} from "reactstrap";

const redirectToLogin = () => {
    if (!window.location.pathname.startsWith("/auth")) {
        window.location.replace("/auth/login");
        return (
            <div style={{ textAlign: "center", verticalAlign: "middle" }}>
                <h4 style={{ marginTop: "25%" }}>Redirecting to login...</h4>
            </div>
        );
    }
};

function getRoleStatus(user) {
    // If user or roles array is missing/empty => treat as not logged in
    if (!user?.roles || user.roles.length === 0) {
        return "ANON";
    }

    // If user has exactly ["no-role"] => pending
    if (user.roles.includes("no-role") && user.roles.length === 1) {
        return "PENDING";
    }

    const hasUS = user.roles.includes("sales-us");
    const hasCA = user.roles.includes("sales-ca");
    const isAdmin = user.roles.includes("admin");

    if (isAdmin || (hasUS && hasCA)) {
        return "MULTI";
    }
    if (hasUS && !hasCA) {
        return "US-ONLY";
    }
    if (hasCA && !hasUS) {
        return "CA-ONLY";
    }

    // Fallback in case of unexpected combos
    return "PENDING";
}

const RouterManager = ({ isLoading, user, showErrorButton, quoteToolClient, isAuthRoute,
                          isLocalHostApiRoute, error, loadingProgress}) => {
    const [message, setMessage] = useState("");
    const [showLoadingScreen, setShowLoadingScreen] = useState(false);

    console.log("RouterPicker", isLoading, isLocalHostApiRoute, isAuthRoute);

    setTimeout(() => setShowLoadingScreen(true), 200);
    setTimeout(() => setMessage("A few more seconds..."), 3000);

    // 1) If path is for local API, redirect
    if (isLocalHostApiRoute) {
        window.location.href = "http://localhost:3001" + window.location.pathname;
        return <h1>Redirecting to API...</h1>;
    }

    // 2) If user is on an auth route
    if (isAuthRoute) {
        return <AuthRoutes />;
    }

    // 3) If app is still loading
    if (isLoading) {
        if (showLoadingScreen) {
            return (
                <div className="text-center">
                    <h1>Quote Tool Loading...</h1>
                    <Progress animated value={loadingProgress} />
                    <LoadingSpinner />
                    <h5 className="fst-italic">{message}</h5>
                </div>
            );
        }
        return null;
    }

    // 4) If no user roles => redirect to login
    if (!user) {
        return redirectToLogin();
    }
    // If roles array is empty => also redirect to login
    if (!user.roles || user.roles.length === 0) {
        return redirectToLogin();
    }

    // 5) Determine role status
    const roleStatus = getRoleStatus(user);

    // If "ANON" => no real roles => redirect to login
    if (roleStatus === "ANON") {
        return redirectToLogin();
    }

    // If "PENDING" => show user pending page
    if (roleStatus === "PENDING") {
        return <UserPendingPage />;
    }

    // If single role => auto-redirect to /US or /CA if user is at "/"
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
        if (roleStatus === "US-ONLY") {
            window.location.replace("/US");
            return null;
        } else if (roleStatus === "CA-ONLY") {
            window.location.replace("/CA");
            return null;
        }
        // If "MULTI" or "admin", stay on "/"
    }

    return (
        <AppRoutes
            quoteToolClient={quoteToolClient}
            user={user}
            showErrorButton={showErrorButton}
        />
    );
};

RouterManager.propTypes = {
    showErrorButton: PropTypes.bool,
    user: PropTypes.object,
    error: PropTypes.object,
    loadingProgress: PropTypes.number,
    isLoading: PropTypes.bool,
    isAuthRoute: PropTypes.bool,
    isLocalHostApiRoute: PropTypes.bool,
    quoteToolClient: PropTypes.object,
}

export default RouterManager;