import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PaymentAndGeneralTermsFlexibleDropdown = ({ header, selectedTerm, onChange, quoteToolClient, termsOptions }) => {
    const [options, setOptions] = useState(termsOptions || []);

    useEffect(() => {
        if (!quoteToolClient.getSaturnPaymentTerms || !quoteToolClient.getSaturnTerms)
            return;
        if (!termsOptions || termsOptions.length === 0) {
            const fetchOptions = async () => {
                try {
                    const options = header === 'Payment Terms'
                        ? await quoteToolClient.getSaturnPaymentTerms()
                        : await quoteToolClient.getSaturnTerms();

                    setOptions(options?.data ? options.data : options || []);
                } catch (error) {
                    console.error(`Failed to fetch ${header}`, error);
                }
            };

            fetchOptions().then(() => {
                console.log("Fetch completed");
            }).catch((error) => {
                console.error("Failed to fetch options", error);
            });

        } else {
            setOptions(termsOptions);
        }
    }, [header, quoteToolClient, termsOptions]);


    return (
        <div className="container small-form-container shipping-speed-container">
            <div className="row mt-2">
                <div className="col-md-6">
                    <div className="form-column">
                        <h4>{header}</h4>
                        <select
                            className="terms-and-conditions my-1 mr-sm-2"
                            value={selectedTerm || ''}
                            onChange={onChange}
                        >
                            <option value="">Select {header}</option>
                            {options.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

PaymentAndGeneralTermsFlexibleDropdown.propTypes = {
    header: PropTypes.string.isRequired,
    selectedTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    termsOptions: PropTypes.array
};

export default PaymentAndGeneralTermsFlexibleDropdown;
