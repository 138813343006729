import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import AccountContactCard from "./AccountContactCard.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const AccountContactsSection = ({ account, quoteToolClient, onAccountUpdated }) => {
    const [contactEditIds, setContactEditIds] = useState([]);
    const [newContact, setNewContact] = useState(null);

    const handleAddNewContact = () => {
        const newContactObj = { name: '', email: '', phoneNumber: '', notes: '' };
        setNewContact(newContactObj);
        setContactEditIds([...contactEditIds, 'new']);
    };

    const handleContactSave = async (contact) => {
        if (!isValidEmail(contact.email)) {
            toast.error('Invalid email address.');
            return;
        }
        try {
            if (contact.id) {
                const savedAccount = await quoteToolClient.patchAccountContact({ accountId: account.id, contactId: contact.id, patch: contact });
                onAccountUpdated(savedAccount);
            } else {
                const savedAccount = await quoteToolClient.createAccountContact({ accountId: account.id, contact });
                setNewContact(null);
                onAccountUpdated(savedAccount);
            }
            setContactEditIds(contactEditIds.filter(id => id !== contact.id && id !== 'new'));
        } catch (error) {
            toast.error('Failed to save contact.');
            console.error(error);
        }
    };

    const handleSetPrimaryContact = async (contactId) => {
        try {
            const savedAccount = await quoteToolClient.patchAccount({ accountId: account.id, patch: { "defaults.primaryContactId": contactId } });
            onAccountUpdated(savedAccount);
        } catch (error) {
            toast.error('Failed to set primary contact.');
            console.error(error);
        }
    };

    return (
        <Row>
            <Col xs="12" className="d-flex justify-content-between align-items-center mb-2">
                <h3>Contacts</h3>
                <Button
                    color="primary"
                    outline
                    onClick={handleAddNewContact}
                >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} /> Contact
                </Button>
            </Col>
            <Row>
                {account.contacts.map((contact, index) => (
                    <Col sm="6" md="4" key={contact.id}>
                        <AccountContactCard
                            contact={contact}
                            quoteToolClient={quoteToolClient}
                            onAccountUpdated={onAccountUpdated}
                            accountId={account.id}
                            isPrimary={account.defaults.primaryContactId === contact.id}
                            isEditing={contactEditIds.includes(contact.id)}
                            onSave={handleContactSave}
                            onSetPrimary={() => handleSetPrimaryContact(contact.id)}
                            onEdit={() => setContactEditIds([...contactEditIds, contact.id])}
                            onCancelEdit={() => setContactEditIds(contactEditIds.filter(id => id !== contact.id))}
                        />
                    </Col>
                ))}
                {newContact && (
                    <Col sm="6" md="4">
                        <AccountContactCard
                            contact={newContact}
                            quoteToolClient={quoteToolClient}
                            isEditing
                            onSave={handleContactSave}
                            onCancelEdit={() => setNewContact(null)}
                        />
                    </Col>
                )}
            </Row>
        </Row>
    );
};

// Helper function for validating email
const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export default AccountContactsSection;