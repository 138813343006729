import React from 'react';

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

const AppContext = React.createContext({
    companies: [],
    currencyRates: {},
});

export default AppContext;