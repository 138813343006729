import React, {useEffect, useState} from 'react';
import {
    BrowserRouter, Link,
    Navigate,
    Route,
    Routes, useNavigate,
    useParams
} from 'react-router-dom';
import ErrorBoundaryWrapper from "../atoms/error/ErrorBoundary.js";
import ErrorNotification from "../atoms/error/ErrorNotification.js";
import ErrorButton from "../atoms/error/ErrorButton.js";
import {WelcomePage} from "../pages/WelcomePage.js";
import AccountsPage from "../pages/AccountsPage.js";
import AccountPage from "../pages/AccountPage.js";
import QuotePage from "../pages/QuotePage.js";
import QuotesPage from "../pages/QuotesPage.js";
import ChangePasswordPage from "../pages/ChangePasswordPage.js";
import UsersPage from "../pages/UsersPage.js";
import PropTypes from "prop-types";
import StoreRedirect from '../pages/CompanyRedirect';
import CompanyDashboard from "../pages/CompanyDashboard";


/**
 * Redirect component that redirects the user to the company select screeen. Using
 * replace true results in this redirect page being skipped when clicking back so the user
 * can hit back to go back to the page they were on previously.
 * @returns {Element}
 * @constructor
 */
function RedirectComponent() {
    const navigate = useNavigate();
    const [redirectTimeout, setRedirectTimeout] = useState(null);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate('/', { replace: true });
        }, 100); // 1 second
        setRedirectTimeout(timeoutId);
        return () => clearTimeout(redirectTimeout); // Clear timeout on unmount
    }, []); // Empty dependency array ensures this runs once

    // ... rest of your component logic

    return (
        <div>
            Please wait to be redirected or <Link className="navbar-link" to="/">
            Click Here
        </Link> if you are not redirected automatically
        </div>
    );
}

function CompanyRouteGuard({ user, children }) {
    const { companyUrlSegment } = useParams();
    const navigate = useNavigate();

    const roles = user?.roles || [];

    const isAdmin = roles.includes("admin");
    const hasUS = roles.includes("sales-us");
    const hasCA = roles.includes("sales-ca");

    if (companyUrlSegment.toUpperCase() === "US") {
        if (!isAdmin && !hasUS) {
          return <RedirectComponent/>
        }
    } else if (companyUrlSegment.toUpperCase()  === "CA") {
        if (!isAdmin && !hasCA) {
            return <RedirectComponent/>
        }
    } else {
        return <RedirectComponent/>
    }
    return children;
}

const AppRoutes = ({ showErrorButton, quoteToolClient, user }) => {
    console.log("AppRoutes");

    return (
        <BrowserRouter>
            <ErrorBoundaryWrapper>
                <ErrorNotification />
                {showErrorButton && <ErrorButton />}
                <Routes caseSensitive={false}>
                    {/* Welcome page for admin or multi-role */}
                    <Route path="/" element={<WelcomePage />} />

                    {/* Company Dashboard */}
                    <Route
                        path="/:companyUrlSegment"
                        element={
                            <CompanyRouteGuard user={user}>
                                <CompanyDashboard quoteToolClient={quoteToolClient} />
                            </CompanyRouteGuard>
                        }
                    />

                    <Route
                        path="/:companyUrlSegment/quotations"
                        element={
                            <CompanyRouteGuard user={user}>
                                <QuotesPage quoteToolClient={quoteToolClient} />
                            </CompanyRouteGuard>
                        }
                    />

                    <Route
                        path="/:companyUrlSegment/quotes"
                        element={
                            <CompanyRouteGuard user={user}>
                                <QuotePage quoteToolClient={quoteToolClient} />
                            </CompanyRouteGuard>
                        }
                    />

                    <Route
                        path="/:companyUrlSegment/quotes/:quoteRef?"
                        element={
                            <CompanyRouteGuard user={user}>
                                <QuotePage quoteToolClient={quoteToolClient} />
                            </CompanyRouteGuard>
                        }
                    />

                    {/* Accounts */}

                    <Route
                        path="/:companyUrlSegment/accounts"
                        element={
                            <CompanyRouteGuard user={user}>
                                <AccountsPage quoteToolClient={quoteToolClient} />
                            </CompanyRouteGuard>
                        }
                    />

                    <Route
                        path="/:companyUrlSegment/accounts/:accountId"
                        element={
                            <CompanyRouteGuard user={user}>
                                <AccountPage quoteToolClient={quoteToolClient} />
                            </CompanyRouteGuard>
                        }
                    />

                    {/* End Accounts */}

                    <Route
                        path="/users/change-password"
                        element={<ChangePasswordPage quoteToolClient={quoteToolClient} />}
                    />

                    {/* Admin-only route */}

                    {user && user.roles.includes("admin") && (
                        <Route
                            path="/admin/users"
                            element={<UsersPage quoteToolClient={quoteToolClient} currentUserId={user.sub} />}
                        />
                    )}

                    {/* Company Redirect */}
                    <Route path="/company/:companyId/*" element={<StoreRedirect />} />

                    {/* Fallback */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </ErrorBoundaryWrapper>
        </BrowserRouter>
    );
};

AppRoutes.propTypes = {
    showErrorButton: PropTypes.bool,
    quoteToolClient: PropTypes.object.isRequired,
    user: PropTypes.object
}

export default AppRoutes;