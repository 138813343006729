import React, { useContext, useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Button, FormFeedback, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { testPasswordStrength } from '@kjdelectronics/ps-quotetool-domain/domain/PasswordStrengthTest.js';
import QuoteToolNavBar from "../molecules/NavBar.js";
import AppContext from "../../context/AppContext.js";

const ChangePasswordPage = ({ quoteToolClient }) => {
    const { user } = useContext(AppContext);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordFeedback, setPasswordFeedback] = useState({ valid: true, errors: [] });
    const [confirmValid, setConfirmValid] = useState(true);

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        const { strong, errors } = testPasswordStrength(value);
        setPasswordFeedback({
            valid: strong,
            errors: strong ? [] : errors,
        });
        if(confirmPassword !== "")
            setConfirmValid(confirmPassword === value);
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        setConfirmValid(newPassword === value);
    };

    const handleChangePassword = async () => {
        if (!passwordFeedback.valid || !confirmValid) {
            toast.error("Please ensure passwords match and meet strength requirements.");
            return;
        }
        setIsLoading(true);
        try {
            await quoteToolClient.changePassword({ oldPassword: currentPassword, newPassword });
            toast.success("Password changed successfully!");
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch(err) {
            toast.error("Failed to change password.");
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <QuoteToolNavBar />
            <Container className="text-center" style={{ marginTop: '20px', maxWidth: '400px' }}>
                <h3>Change Password</h3>
                <p className="text-muted">{user.email}</p>
                <Form>
                    <FormGroup>
                        <Label for="currentPassword">Current Password</Label>
                        <Input
                            type="password"
                            id="currentPassword"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder="Enter current password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="newPassword">New Password</Label>
                        <Input
                            type="password"
                            id="newPassword"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            placeholder="Enter new password"
                            valid={passwordFeedback.valid && newPassword.length > 0}
                            invalid={!passwordFeedback.valid && newPassword.length > 0}
                        />
                        <FormFeedback valid={passwordFeedback.valid}>
                            {passwordFeedback.valid ? (
                                "Password is strong!"
                            ) : (
                                <>
                                    <strong>Invalid Password</strong>
                                    <ul>
                                        {passwordFeedback.errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmPassword">Confirm New Password</Label>
                        <Input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            onBlur={handleConfirmPasswordChange}
                            placeholder="Confirm new password"
                            valid={confirmValid && confirmPassword.length > 0}
                            invalid={!confirmValid && confirmPassword.length > 0}
                        />
                        <FormFeedback valid={confirmValid}>
                            {confirmValid ? "Passwords match!" : "Passwords do not match."}
                        </FormFeedback>
                    </FormGroup>
                    <Button color="primary" onClick={handleChangePassword} disabled={isLoading} block>
                        {isLoading ? <Spinner bsSize="sm" /> : 'Change Password'}
                    </Button>
                </Form>
            </Container>
        </>
    );
};

export default ChangePasswordPage;
