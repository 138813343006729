import React from 'react';
import withRouter from '../../helper/withRouter';
import { getUrlSegmentFromCompanyId } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";

class CompanyRedirect extends React.Component {
    componentDidMount() {
        const { companyId } = this.props.router.params;
        const urlSegment = getUrlSegmentFromCompanyId(parseInt(companyId, 10));

        if (urlSegment) {
            // Construct the new path
            const remainingPath = this.props.router.location.pathname.split(`/company/${companyId}`)[1] || '';
            const newPath = `/${urlSegment}${remainingPath}${this.props.router.location.search}`;

            // Redirect to the new path
            this.props.router.navigate(newPath, { replace: true });
        } else {
            this.props.router.navigate('/', { replace: true });
        }
    }

    render() {
        return null; // Could also do a loading indicator or somthing of the sort instead
    }
}

export default withRouter(CompanyRedirect);